<template>
    <div class="sup_content language-list">
        <div class="sup_page_heading">
            <h2>Device Informations</h2>
        </div>
        <div class="sup_inner_content">
            <!-- - Show Device Info list Start -
            <DataTable class="p-datatable" :paginator="true" :value="allDeviceData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allDeviceData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading languages data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">-->
                            <!-- <Button :label="allDeviceData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allDeviceData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allDeviceData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllDeviceList(allDeviceData.activityStatus, 'languageStatus')" /> -->
                        <!-- </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allDeviceData.search" placeholder="Search Device" @keyup.enter="searchAllDeviceList(allDeviceData.search)"/>
                            </span>
                            <Button v-if="allDeviceData.search !== null || allDeviceData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllDeviceList(allDeviceData.activityStatus, 'resetFilters')"/> -->
                            <!-- <Calendar v-model="allDeviceData.dateRange" placeholder="DD.MM.YY" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" :showIcon="true" @date-select="allDeviceData.dateRange[1] != null ? fetchAllDeviceList(allDeviceData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <SplitButton label="Export" icon="pi pi-external-link" class="p-button-secondary p-ml-2" :model="exportType" @click="exportXL('excel')"></SplitButton> -->
                       <!-- </div>
                    </div>
                </template>
                <template #empty>
                    No information found...
                </template>
                <Column field="Website" header="Website Url" :sortable="true">
                    <template #body="slotProps">
                        <strong>{{slotProps.data.website}}</strong>
                    </template>
                </Column>

                <Column field="ipAddress" header="Ip Address" >
                    <template #body="slotProps">
                        {{!slotProps.data.ipAddress ? "NA" : slotProps.data.ipAddress}}
                    </template>
                </column>

                <Column field="deviceId" header="Device Info" >
                    <template #body="slotProps">
                        {{!slotProps.data.deviceId ? "NA" : slotProps.data.deviceId}}
                    </template>
                </column>

                <Column field="deviceType" header="Device Type">
                    <template #body="slotProps">
                        {{!slotProps.data.deviceType ? "NA" : capitalizeCase(slotProps.data.deviceType)}}
                    </template>
                </Column>

                <Column field="ipLatitude" header="Latitude" >
                    <template #body="slotProps">
                        {{!slotProps.data.ipLatitude ? "NA" : slotProps.data.ipLatitude.toPrecision(6) }}
                    </template>
                </column>

                <Column field="ipLongitude" header="Longitude" >
                    <template #body="slotProps">
                        {{!slotProps.data.ipLongitude ? "NA" : slotProps.data.ipLongitude.toPrecision(6) }}
                    </template>
                </column>

                <Column field="ipRegionName" header="Address" >
                    <template #body="slotProps">
                        {{!slotProps.data.ipRegionName ? "NA" : slotProps.data.ipRegionName}}<br>
                        {{ !slotProps.data.ipCountryName ? "NA" : slotProps.data.ipCountryName }}
                    </template>
                </column>

                <Column field="createdAt" header="Created On">
                    <template #body="slotProps">
                        {{usFormatDate(slotProps.data.createdAt)}}
                    </template>
                </Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-eye" class="p-button-sm p-button-rounded p-button-dark p-mr-1" @click="viewDeviceInfo(slotProps.data)" v-tooltip="'View More'" />
                    </template>
                </Column>
            </DataTable> -->
            <!--- Show Device Info list End --->

        <!-- New Custom Datatable -->
            <div class="new_dataTable_wrapper">
                <div class="device_table_top">
                    <div class="deviceSearch">
                        <div class="text_wrapper">
                            <!-- <i class="pi pi-search"></i> -->
                            <InputText v-model="allDeviceData.search" placeholder="Search Device" @keyup.enter="searchAllDeviceList(allDeviceData.search)"/>
                            <Button v-if="allDeviceData.search !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="allPaginatedDeviceList('reset')"/>
                        </div>
                        
                    </div>
                </div>
                <div class="device_table_panel">
                    <table>
                        <thead>
                            <tr>
                                <th>Website Url</th>
                                <th>Ip Address</th>
                                <th>Device Info</th>
                                <th>Device Type</th>
                                <th>Latitude</th>
                                <th>Longitude</th>
                                <th>Address</th>
                                <th>Created On</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody v-if="allDeviceData.data.length !== 0">
                            <tr v-for="data in allDeviceData.data" :key="data">
                                <td>{{data.website ? data.website : 'NA'}}</td>
                                <td>{{data.ipAddress ? data.ipAddress : 'NA'}}</td>
                                <td>{{data.deviceId ? data.deviceId : 'NA'}}</td>
                                <td>{{data.deviceType ? data.deviceType : 'NA'}}</td>
                                <td>{{data.ipLatitude ? (data.ipLatitude).toFixed(4) : 'NA'}}</td>
                                <td>{{data.ipLongitude ? (data.ipLongitude).toFixed(4) : 'NA'}}</td>
                                <td>{{(data.ipRegionName && data.ipCountryName) ?
                                (data.ipRegionName) + ' ' + (data.ipCountryName) : 'NA'}}</td>
                                <td>{{usFormatDate(data.createdAt)}}</td>
                                <td>
                                    <Button type="button" icon="pi pi-eye" class="p-button-sm p-button-rounded p-button-dark p-mr-1" v-tooltip="'View More'" @click="viewDeviceInfo(data)" />
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="8">No Data Available</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="device_table_footer">
                    <!-- <div class="show_data_result">Showing 1 of 1</div> -->
                    <div class="tablePagination_pageper_wrapper">
                        <div class="pagiNation">
                            <button class="pagi_btn prev" @click="allPaginatedDeviceList(1)">
                                <i class="pi pi-angle-left"></i>
                            </button>
                            <ul>
                                <li v-if="allDeviceData.previousPage.page !== 0">
                                    <span @click="allPaginatedDeviceList(allDeviceData.previousPage.page)">{{allDeviceData.previousPage.page}}</span>
                                </li>
                                <li class="active"><span>{{allDeviceData.page}}</span></li>
                                <li v-if="allDeviceData.totalCount >= allDeviceData.nextPage.page" >
                                    <span :v-model="allDeviceData.nextPage.page" @click="allPaginatedDeviceList(allDeviceData.nextPage.page)">
                                    {{allDeviceData.nextPage.page}}</span>
                                </li>
                                <li v-if="allDeviceData.totalCount >= (allDeviceData.nextPage.page)+1" >
                                    <span :v-model="allDeviceData.nextPage.page" @click="allPaginatedDeviceList((allDeviceData.nextPage.page)+1)">
                                    {{allDeviceData.nextPage.page +1}}</span>
                                </li>
                                <li v-if="allDeviceData.totalCount > allDeviceData.nextPage.page"><span class="more"><i class="pi pi-ellipsis-h"></i></span></li>
                            </ul>
                            <button class="pagi_btn next" @click="allPaginatedDeviceList(allDeviceData.totalCount)">
                                <i class="pi pi-angle-right"></i>
                            </button>
                        </div>
                        <div class="pagePerShow">
                            <Dropdown v-model="deviceLimitData" :options="deviceLimitDatas" id="u_licence_status" 
                            optionLabel="name" optionValue="code" placeholder="Select Size" @change="allPaginatedDeviceList('limit')"/>
                        </div>
                    </div>
                </div>
            </div>
            <!--- View Website Modal Start --->
            <Dialog v-model:visible="viewdeviceInfoModal.modalState" :modal="true" :style="{width: '600px'}">
                <template #header>
                    <span class="p-dialog-title">Device Details</span>
                </template>
                <!-- {{viewdeviceInfoModal.modaldata}} -->
                <div class="sup_dialog_content sup_website_info">
                    <div class="sup_website_info_section">
                        <div class="sup_section_title">Site info:</div>
                        <ul>
                            <!-- <li><strong>Name:</strong> {{viewdeviceInfoModal.modaldata.Website.siteName.charAt(0).toUpperCase() + viewdeviceInfoModal.modaldata.Website.siteName.slice(1)}}</li> -->
                            <li><strong>Url:</strong> <a :href="'http://' + viewdeviceInfoModal.modaldata.website">{{viewdeviceInfoModal.modaldata.website}}</a></li>
                        </ul>
                    </div>
                    <div v-if="viewdeviceInfoModal.modaldata !== null" class="sup_website_info_section">
                        <div class="sup_section_title">Device Information</div>
                        <ul>
                            <li><strong>Device Browser:</strong>
                                <div v-if="viewdeviceInfoModal.modaldata.deviceBrowser">
                                    <li>Type: {{ !viewdeviceInfoModal.modaldata.deviceBrowser.type ? 'NA' : viewdeviceInfoModal.modaldata.deviceBrowser.type }}</li>
                                    <li>Name: {{ !viewdeviceInfoModal.modaldata.deviceBrowser.name ? 'NA' : viewdeviceInfoModal.modaldata.deviceBrowser.name }}</li>
                                    <li>Short Name: {{ !viewdeviceInfoModal.modaldata.deviceBrowser.short_name ? 'NA' : viewdeviceInfoModal.modaldata.deviceBrowser.short_name }}</li>
                                    <li>Version: {{ !viewdeviceInfoModal.modaldata.deviceBrowser.version ? 'NA' : viewdeviceInfoModal.modaldata.deviceBrowser.version }}</li>
                                    <li>Engine: {{ !viewdeviceInfoModal.modaldata.deviceBrowser.engine ? 'NA' : viewdeviceInfoModal.modaldata.deviceBrowser.engine }}</li>
                                    <li>Engine Version: {{ !viewdeviceInfoModal.modaldata.deviceBrowser.engine_version ? 'NA' : viewdeviceInfoModal.modaldata.deviceBrowser.engine_version }}</li>
                                    <li>Family: {{ !viewdeviceInfoModal.modaldata.deviceBrowser.family ? 'NA' : viewdeviceInfoModal.modaldata.deviceBrowser.family }}</li>
                                </div>
                                <div v-else>NA</div>
                            </li>
                            <li><strong>Device Id:</strong> {{!viewdeviceInfoModal.modaldata.deviceId ? 'NA' : viewdeviceInfoModal.modaldata.deviceId}}</li>
                            <li><strong>Device Index Db Support:</strong> {{!viewdeviceInfoModal.modaldata.deviceIndexDbSupport ? 'NA' : viewdeviceInfoModal.modaldata.deviceIndexDbSupport}}</li>
                            <li><strong>Device Local Storage Support:</strong> {{!viewdeviceInfoModal.modaldata.deviceLocalStorageSupport ? 'NA' : viewdeviceInfoModal.modaldata.deviceLocalStorageSupport}}</li>
                            <li><strong>Device Operating System:</strong>
                                <div v-if="viewdeviceInfoModal.modaldata.deviceOs">
                                    <li>Name: {{ !viewdeviceInfoModal.modaldata.deviceOs.name ? 'NA' : viewdeviceInfoModal.modaldata.deviceOs.name }}</li>
                                    <li>Short Name: {{ !viewdeviceInfoModal.modaldata.deviceOs.short_name ? 'NA' : viewdeviceInfoModal.modaldata.deviceOs.short_name }}</li>
                                    <li>Version: {{ !viewdeviceInfoModal.modaldata.deviceOs.version ? 'NA' : viewdeviceInfoModal.modaldata.deviceOs.version }}</li>
                                    <li>Platform: {{ !viewdeviceInfoModal.modaldata.deviceOs.platform ? 'NA' : viewdeviceInfoModal.modaldata.deviceOs.platform }}</li>
                                    <li>Family: {{ !viewdeviceInfoModal.modaldata.deviceOs.family ? 'NA' : viewdeviceInfoModal.modaldata.deviceOs.family }}</li>
                                </div>
                                <div v-else>NA</div>
                            </li>
                            <li><strong>Device Screen Resolution:</strong> {{!viewdeviceInfoModal.modaldata.deviceScreenResolution ? 'NA' : viewdeviceInfoModal.modaldata.deviceScreenResolution}}</li>
                            <li><strong>Device TimeZone:</strong> {{!viewdeviceInfoModal.modaldata.deviceTimeZone ? 'NA' : viewdeviceInfoModal.modaldata.deviceTimeZone}}</li>
                            <li><strong>Device Type</strong> {{!viewdeviceInfoModal.modaldata.deviceType ? 'NA' : viewdeviceInfoModal.modaldata.deviceType}}</li>
                            <li><strong>Device Type Description:</strong> 
                                <div v-if="viewdeviceInfoModal.modaldata.deviceTypeDescription">
                                    <li>Id: {{ !viewdeviceInfoModal.modaldata.deviceTypeDescription.id ? 'NA' : viewdeviceInfoModal.modaldata.deviceTypeDescription.id }}</li>
                                    <li>type: {{ !viewdeviceInfoModal.modaldata.deviceTypeDescription.type ? 'NA' : viewdeviceInfoModal.modaldata.deviceTypeDescription.type }}</li>
                                    <li>Version: {{ !viewdeviceInfoModal.modaldata.deviceTypeDescription.brand ? 'NA' : viewdeviceInfoModal.modaldata.deviceTypeDescription.brand }}</li>
                                    <li>Platform: {{ !viewdeviceInfoModal.modaldata.deviceTypeDescription.model ? 'NA' : viewdeviceInfoModal.modaldata.deviceTypeDescription.model }}</li>
                                </div>
                                <div v-else>NA</div>
                            </li>
                            <li><strong>Device User Agent</strong> {{!viewdeviceInfoModal.modaldata.deviceUserAgent ? 'NA' : viewdeviceInfoModal.modaldata.deviceUserAgent}}</li>
                            <li><strong>Device ip Address: :</strong> {{!viewdeviceInfoModal.modaldata.ipAddress ? 'NA' : viewdeviceInfoModal.modaldata.ipAddress}}</li>
                            <li><strong>Ip City:</strong> {{!viewdeviceInfoModal.modaldata.ipCity ? 'NA' : viewdeviceInfoModal.modaldata.ipCity}}</li>

                            <li><strong>Ip Continent Code:</strong> {{!viewdeviceInfoModal.modaldata.ipContinentCode ? 'NA' : viewdeviceInfoModal.modaldata.ipContinentCode}}</li>
                            <li><strong>Ip Continent Name:</strong> {{!viewdeviceInfoModal.modaldata.ipContinentName ? 'NA' : viewdeviceInfoModal.modaldata.ipContinentName}}</li>
                            <li><strong>Ip Count:</strong> {{!viewdeviceInfoModal.modaldata.ipCount ? 'NA' : viewdeviceInfoModal.modaldata.ipCount}}</li>
                            <li><strong>Ip Country Code:</strong> {{!viewdeviceInfoModal.modaldata.ipCountryCode ? 'NA' : viewdeviceInfoModal.modaldata.ipCountryCode}}</li>
                            <li><strong>Ip Country Name:</strong> {{!viewdeviceInfoModal.modaldata.ipCountryName ? 'NA' : viewdeviceInfoModal.modaldata.ipCountryName}}</li>
                            <li><strong>Ip Currency Code:</strong> {{!viewdeviceInfoModal.modaldata.ipCurrencyCode ? 'NA' : viewdeviceInfoModal.modaldata.ipCurrencyCode}}</li>
                            <li><strong>Ip Isp:</strong> {{!viewdeviceInfoModal.modaldata.ipIsp ? 'NA' : viewdeviceInfoModal.modaldata.ipIsp}}</li>
                            <li><strong>Ip Latitude:</strong> {{!viewdeviceInfoModal.modaldata.ipLatitude ? 'NA' : viewdeviceInfoModal.modaldata.ipLatitude}}</li>
                            <li><strong>Ip Longitude:</strong> {{!viewdeviceInfoModal.modaldata.ipLongitude ? 'NA' : viewdeviceInfoModal.modaldata.ipLongitude}}</li>
                            <li><strong>Ip Region Code:</strong> {{!viewdeviceInfoModal.modaldata.ipRegionCode ? 'NA' : viewdeviceInfoModal.modaldata.ipRegionCode}}</li>
                            <li><strong>Ip Region Language:</strong> {{!viewdeviceInfoModal.modaldata.ipRegionLanguage ? 'NA' : viewdeviceInfoModal.modaldata.ipRegionLanguage}}</li>

                            <li><strong>Ip Region Name: </strong> {{!viewdeviceInfoModal.modaldata.ipRegionName ? 'NA' : viewdeviceInfoModal.modaldata.ipRegionName}}</li>
                            <li><strong>Ip Time Zone:</strong> {{!viewdeviceInfoModal.modaldata.ipTimeZone ? 'NA' : viewdeviceInfoModal.modaldata.ipTimeZone}}</li>
                            <li><strong>Ip TimeZone Code:</strong> {{!viewdeviceInfoModal.modaldata.ipTimeZoneCode ? 'NA' : viewdeviceInfoModal.modaldata.ipTimeZoneCode}}</li>
                            <li><strong>Ip Type: </strong> {{!viewdeviceInfoModal.modaldata.ipType ? 'NA' : viewdeviceInfoModal.modaldata.ipType}}</li>
                            <li><strong>Ip Zip:</strong> {{!viewdeviceInfoModal.modaldata.ipZip ? 'NA' : viewdeviceInfoModal.modaldata.ipZip}}</li>
                        </ul>
                    </div>
                </div>
            </Dialog>
            <!--- View Website Modal End --->
        </div>
    </div>
</template>

<script>
import { onBeforeMount, reactive } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { allDeviceList, allDeviceListExport, deviceInfoById,searchDeviceInfo } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import { formatDate, usFormatDate, capitalizeCase } from "../../../../helper/utility/utility";
const FileSaver = require('file-saver');
import { ref } from 'vue';

export default {
    name: "DeviceInfo",
    setup(){
        //Store call
        let store = useStore();

        //Device Initial state
        const allDeviceData = reactive({
            data: [],
            total: null,
            status: null,
            loading: false,
            activityStatus: 'active',
            search: null,
            dateRange: null,
            page : 1,
            pageLimit : 10,
            previousPage : 0,
            nextPage : 2,
            totalCount : null
        });

        const deviceLimitDatas = ref([
            {name: 1, code:1},
            {name: 10, code:10},
            {name: 50, code:50},
            {name: 100, code:100},
            {name: 500, code:500},
            {name: 1000, code:1000}
        ])

        const deviceLimitData = ref()

        //Device detail view modal state
        const viewdeviceInfoModal = reactive({
            modalState: false,
            modaldata: ''
        })

        //Export type
        const exportType = reactive([
            {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                command: () => {
                    exportXL('excel');
                }
            },
            {
                label: 'PDF',
                icon: 'pi pi-file-pdf',
                command: () => {
                    exportXL('pdf');
                }
            }
        ]);

        //On before mount function invoke
        onBeforeMount(async()=>{
            getAllDeviceList();
        });

        //Fetch All Device Key
        const fetchAllDeviceList = async (activityStatus, load) =>{
            let date = null;
            if(load === "languageStatus"){
                allDeviceData.activityStatus = activityStatus === 'active' ? 'de-active' : 'active';
            }

            if(load === "resetFilters"){
                allDeviceData.search = null;
                allDeviceData.dateRange = null;
            }

            if (load === 'dateRange') {
                date = formatDate(allDeviceData.dateRange[0]) + ' ' + formatDate(allDeviceData.dateRange[1]);
            }

            try{
                allDeviceData.loading = !allDeviceData.loading;
                const response = await axios.get(allDeviceList, {
                    params: {
                        status: allDeviceData.activityStatus,
                        search: allDeviceData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                allDeviceData.data = response.data.data.deviceInfo;
                allDeviceData.loading = !allDeviceData.loading;
            }catch(err){
                console.log('error', err);
            }
        }

        //Export Device list
        const exportXL = async (load) => {
            let date = null;
            if (load === 'excel') {
                try{
                    if (allDeviceData.dateRange !== null) {
                        date = formatDate(allDeviceData.dateRange[0]) + ' ' + formatDate(allDeviceData.dateRange[1]);
                    }
                    const response = await axios.get( allDeviceListExport, {
                        responseType: 'arraybuffer',
                        params: {
                            exe: "xlsx",
                            print: false,
                            status: allDeviceData.activityStatus,
                            search: allDeviceData.search,
                            filterDt: date
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var fileName = dirtyFileName.match(regex)[3];
                        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver(blob, fileName);
                    }
                } catch(err) {
                    console.log('error', err);
                }   
            }
        }

        /**
         * On view and edit deviceInfo UI content update by respective deviceInfo data
         */
        const viewDeviceInfo = async (slotData) => {
            const response = await axios.get( deviceInfoById, {
                params: {
                    browserName: slotData.deviceBrowser.name,
                    ipAddress: slotData.ipAddress,
                    website: slotData.website
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                viewdeviceInfoModal.modalState = true;
                viewdeviceInfoModal.modaldata = response.data.data;
                }
        }

        const searchAllDeviceList = async(slotData) => {
            try{
                allDeviceData.loading = !allDeviceData.loading;
                const response = await axios.get(searchDeviceInfo, {
                    params: {
                        search: slotData
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                allDeviceData.data = response.data.data.deviceInfo;
                allDeviceData.loading = !allDeviceData.loading;
            }catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const getAllDeviceList = async() => {
            try{
                allDeviceData.loading = !allDeviceData.loading;
                const response = await axios.get(allDeviceList, {
                    params: {
                        page : allDeviceData.page,
                        limit : allDeviceData.pageLimit,
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                allDeviceData.data = response.data.data.results;
                allDeviceData.nextPage = response.data.data.next;
                allDeviceData.previousPage = response.data.data.previous;
                allDeviceData.totalCount = response.data.data.totalCount;
                allDeviceData.loading = !allDeviceData.loading;
            }catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const allPaginatedDeviceList = async(load) => {
            if (load === 'limit') {
                allDeviceData.pageLimit = deviceLimitData.value;
            } else if(load === 'reset'){
                allDeviceData.search = null;
            } else {
                allDeviceData.page = load
            }
            try{
                allDeviceData.loading = !allDeviceData.loading;
                const response = await axios.get(allDeviceList, {
                    params: {
                        page : allDeviceData.page,
                        limit : allDeviceData.pageLimit,
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                allDeviceData.data = response.data.data.results;
                allDeviceData.nextPage = response.data.data.next;
                allDeviceData.previousPage = response.data.data.previous;
                allDeviceData.totalCount = response.data.data.totalCount;
                allDeviceData.loading = !allDeviceData.loading;
            }catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        return{
            exportType,
            allDeviceData,
            fetchAllDeviceList,
            usFormatDate,
            capitalizeCase,
            exportXL,
            viewDeviceInfo,
            viewdeviceInfoModal,
            searchAllDeviceList,
            deviceLimitDatas,
            deviceLimitData,
            allPaginatedDeviceList
        }
    }
}
</script>

<style  lang="scss" scoped>
@import '../../../../assets/style/component/superAdmin/pages/widgetManagement/DeviceInfo';

.sup_website_info_section {
  border: 1px solid #5385e2;
  margin-bottom: 15px;
  border-radius: 5px;
}

.sup_website_info_section .sup_section_title {
  background-color: #5385e2;
  padding: 5px 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #ffffff;
  line-height: 1.4;
  font-weight: 600;
}

.sup_website_info_section .sup_section_title i.pi {
  margin-right: 5px;
}

.sup_website_info_section ul {
  padding: 10px 15px;
  list-style: none;
}

.sup_website_info_section ul li {
  font-size: 15px;
  line-height: 1.2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}

.sup_website_info_section ul li strong {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 200px;
  text-align: left;
  display: inline-block;
  margin-right: 15px;
  color: #333333;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}


</style>